import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Slant as Hamburger } from 'hamburger-react'
import { TbQuestionMark } from "react-icons/tb"
import { IoMdClose } from "react-icons/io"
import { FaInstagram, FaFacebook } from "react-icons/fa"
import { AiOutlineLinkedin } from "react-icons/ai"
import ContactMenu from './ContactMenu.js'
import './Menu.css'
import { Link as ScrollLink } from 'react-scroll'
import { menuContent } from '../assets/content/content.js'
import { getLanguage } from './helpers/helpers.js'
import { GrLanguage } from "react-icons/gr"
import { setLanguage } from './helpers/helpers.js'
import { RiArrowGoBackLine } from "react-icons/ri"

function Menu({ setShowWelcome, menuLogo, isLogoVisible, includeGoBack }) {
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [contactIsOpen, setContactIsOpen] = useState(false)
  const [contactType, setContactType] = useState("Contact")

  const navigate = useNavigate()
  const selectedLang = getLanguage()
  const content = menuContent[selectedLang]

  useEffect(() => {
    const menuItems = document.querySelectorAll('.menu_item')
    const socials = document.querySelector('.socials')
    const menuButtons = document.querySelector('.menu_buttons')
    const contactForm = document.querySelector('#contact-form--basic')

    if (menuIsOpen) {
      menuItems.forEach((item, index) => {
        item.style.animation = `fadeInUp 0.5s ease forwards ${index * 0.1 + 0.75}s`
      })

      socials.style.animation = `fadeInUp 0.5s ease forwards ${menuItems.length * 0.1 + 0.75}s`
      menuButtons.style.animation = `fadeInUp 0.5s ease forwards ${menuItems.length * 0.1 + 0.75}s`

    } else {
      menuItems.forEach((item) => {
        item.style.animation = 'none'
      })
    }

    if (contactIsOpen && contactForm) {
      contactForm.style.animation = `fadeInUp 0.5s ease forwards ${0.1 + 0.75}s`
    }

  }, [menuIsOpen, contactIsOpen])

  const handleOpenContact = (type) => {
    setMenuIsOpen(false)
    setContactIsOpen(true)
    setContactType(type)
  }

  const handleLinkClick = (path, hash) => {
    navigate(path, { replace: true })
    setMenuIsOpen(false)

    if (hash) {
      setTimeout(() => {
        const section = document.querySelector(hash)
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' })
        }
      }, 350)
    }
  }


  const handleSelectLanguage = (lang) => {
    setLanguage(lang)
    navigate(0)
  }

  const handleOnClickHome = () => {
    navigate('/#home')
    window.scrollTo(0, 0)
  }

  return (
    <>
      <div className={`popup_out_menu ${menuIsOpen || contactIsOpen ? "active" : ""}`}>

        <div className='menu_wrapper'>

          <img className='logo--menu' src={'/static/media/logo_vitruvi_transparant_color.591e46c90695be123a04.png'} alt="Menu Logo" />

          {
            menuIsOpen && (
              <div className='language_selector'>
                <GrLanguage />
                <p className={`${selectedLang == "nl" ? "selected" : ""}`} onClick={() => handleSelectLanguage("nl")}>NL</p>
                <p className={`${selectedLang == "fr" ? "selected" : ""}`} onClick={() => handleSelectLanguage("fr")}>FR</p>
                <p className={`${selectedLang == "en" ? "selected" : ""}`} onClick={() => handleSelectLanguage("en")}>EN</p>
              </div>
            )
          }

          {
            menuIsOpen &&
            <div className='menu_section'>
              <ul className='menu'>
                {/* <li className='menu_item' onClick={() => handleLinkClick('/', '#about')}>{content.overOns}</li> */}
                <li className='menu_item' onClick={() => navigate('/about')}>{content.overOns}</li>
                <li className='menu_item' onClick={() => handleLinkClick('/', '#featured-project')}>{content.projecten}</li>
                <li className='menu_item' onClick={() => navigate(`/news`)}>{content.nieuws}</li>
                <li className='menu_item' onClick={() => navigate(`/jobs`)}>{content.vacatures}</li>
                {/* <li className='menu_item' onClick={() => navigate(`/esg`)}>{content.esg}</li> */}
                <li className='menu_item' onClick={() => navigate(`/invest`)}>{content.invest}</li>
                <li className='menu_item' onClick={() => handleLinkClick('/', '#contact')}>{content.contact}</li>
              </ul>

              <div className='socials'>
                <a href='https://www.instagram.com/vitruvi.be/' target='_blank' rel='noreferrer'><FaInstagram /></a>
                <a href='https://www.facebook.com/vitruvi.be/' target='_blank' rel='noreferrer'><FaFacebook /></a>
                <a href='https://www.linkedin.com/company/vitruviprojects/' target='_blank' rel='noreferrer'><AiOutlineLinkedin /></a>
              </div>

              <div className='menu_buttons'>
                <button onClick={() => handleOpenContact("Contact")}>{content.contacteerOns}</button>
                <button onClick={() => handleOpenContact("Afspraak aanvraag")}>{content.maakEenAfspraak}</button>
              </div>
            </div>
          }

          {
            contactIsOpen &&
            <>
              <ContactMenu id={'contact-form--basic'} tag={{ "type": contactType, "name": contactType }} />
            </>
          }
        </div>
      </div>

      <div className='fixed_elements'>
        <div className='action_buttons'>
          {
            !contactIsOpen &&
            <div className='action_button' onClick={() => setMenuIsOpen(!menuIsOpen)}>
              <Hamburger toggled={menuIsOpen} toggle={setMenuIsOpen} size={14} direction='right' />
              <p>{menuIsOpen ? content.close : content.menu}</p>
            </div>
          }
          {
            !menuIsOpen &&
            <div className='action_button question' onClick={() => setContactIsOpen(!contactIsOpen)}>
              {
                contactIsOpen ?
                  <>
                    <IoMdClose id='question-mark-icon' />
                    <p>{content.close}</p>
                  </> :
                  <>
                    <TbQuestionMark id='question-mark-icon' />
                    <p>{content.stelEenVraag}</p>
                  </>
              }
            </div>
          }
          {
            (!contactIsOpen & !menuIsOpen & includeGoBack) ?
              <div className='action_button back' onClick={() => navigate(-1)}>
                <RiArrowGoBackLine id="go-back-icon" />
                <p>{content.goBack}</p>
              </div> : null
          }
        </div>
        {
          !contactIsOpen && !menuIsOpen &&
          <img className={`logo ${isLogoVisible ? '' : 'faded_away'}`} src={menuLogo} alt="Home Logo" onClick={handleOnClickHome} />
        }
      </div>
    </>
  )
}

Menu.defaultProps = {
  isLogoVisible: true,
  menuLogo: require('../assets/logo/logo_vitruvi_transparant_wit.png'),
  includeGoBack: false
}

export default Menu

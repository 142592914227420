import { useEffect, useState } from "react"
import httpClient from "./components/helpers/httpClient"
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { getLanguage, setLanguage, getDefaultLanguage } from "./components/helpers/helpers"
import HomePage from "./pages/HomePage"
import ProjectPage from "./pages/ProjectPage"
import NewsPage from "./pages/NewsPage"
import NewsOverviewPage from "./pages/NewsOverviewPage"
import JobsPage from "./pages/JobsPage"
import LegalPage from "./pages/LegalPage"
import AboutPage from "./pages/AboutPage"
import ESGPage from "./pages/ESGPage"
import InvestPage from "./pages/InvestPage"
import CookieAccepter from "./components/CookieAccepter"
import { projects } from "./assets/content/projects"

function App() {

  const [projects, setProjects] = useState([])

  const fetchProjects = async () => {
    const response = await httpClient.get(`${process.env.REACT_APP_API_URL}/api/projects/get?type=website`)

    if (response.status == 200) {
      setProjects(response.data)
    }
  }

  const setInitialLanguage = async () => {
    if (!localStorage.getItem('lang')) {
      const defaultLang = await getDefaultLanguage()
      setLanguage(defaultLang)
    }
  }

  useEffect(() => {
    setInitialLanguage()
    fetchProjects()
  }, [])

  return (
    <div className="App">
      <ToastContainer position="top-center" autoClose={5000} />
      <CookieAccepter />
      
      <Router>
        <Routes>
          <Route path="/" element={<HomePage projects={projects} />} />
          <Route path="/projects/:projectID" element={<ProjectPage projects={projects} />} />
          <Route path="/news/:newsID" element={<NewsPage />} />
          <Route path="/news" element={<NewsOverviewPage />} />
          <Route path="/jobs" element={<JobsPage />} />
          {/* <Route path="/esg" element={<ESGPage />} /> */}
          <Route path="/invest" element={<InvestPage />} />
          <Route path="/legal" element={<LegalPage />} />
          <Route path="/about" element={<AboutPage />} />
        </Routes>
      </Router>
    </div>
  )
}

export default App
